<template>
    <div>
        <b-card
            class="mt-2"
            title="Filter"
            title-tag="h5"
        >
            <b-input placeholder="Search information..." v-model="filter"></b-input>
            <b-checkbox-group
                class="mt-3"
                v-model="show"
            >
                <b-check
                    value="active"
                >
                    Active Users
                </b-check>
                <b-check
                    value="inactive"
                >
                    Inactive Users
                </b-check>
            </b-checkbox-group>
        </b-card>
        <ChangeUserRegistrationAllowedAlert/>
        <div
            class="scroll-x"
        >
            <b-table
                class="mt-2 w-100"
                :busy="loading"
                striped
                bordered
                hover
                :items="members"
                :fields="tableFields"
                selectable
                select-mode="single"
                @row-selected="onRowSelected"
                :filter="filter"
                empty-text="No members to display."
                empty-filtered-text="No members currently match your filter."
                show-empty
            >
                <template #cell(pos)="row">
                    {{ row.index + 1 }}
                </template>
                <template #cell(user.email)="{ item }">
                    <a :href="'mailto:' + item.user.email">{{ item.user.email }}</a>
                </template>
                <template #cell(actions)="row">
                    <a class="close" @click="row.toggleDetails">
                        <font-awesome-icon icon="info-circle"></font-awesome-icon>
                    </a>
                </template>
                <template #cell(is_active)="row">
                    <user-active-toggle
                        :id="row.item.user.id"
                        v-model="row.item.is_active"
                    />
                </template>
                <template #cell(registration_open)="row">
                    <user-allow-registration-toggle
                        :id="row.item.user.id"
                        v-model="row.item.registration_open"
                    />
                </template>
                <template #row-details="{ toggleDetails, item }">
                    <UserRowDetails
                        @toggle="toggleDetails"
                        :row="item"
                        @refresh="$emit('refresh')"
                    />
                </template>
                <template #empty-text>
                    There are no records to fetch.
                </template>
            </b-table>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import UserActiveToggle from './toggle/UserActiveToggle.vue';
import UserAllowRegistrationToggle from './toggle/UserAllowRegistrationToggle.vue';
import UserRowDetails from './UserRowDetails.vue';
import ChangeUserRegistrationAllowedAlert from './alerts/ChangeUserRegistrationAllowedAlert.vue';

export default {
    props: ["loading", "items"],
    data() {
        return {
            show: [ "active" ],
            filter: "",
            fields: [
                {
                    key: "pos",
                    label: "#",
                    sortable: false
                },
                {
                    key: "user.person.first_name",
                    label: "First Name",
                    sortable: true
                },
                {
                    key: "user.person.last_name",
                    label: "Last Name",
                    sortable: true
                },
                {
                    key: "user.email",
                    label: "Email",
                    sortable: true
                },
                {
                    key: "is_active",
                    label: "Active",
                    sortable: true
                }
            ]
        };
    },
    methods: {
        onRowSelected(row) {
            row._details = !row._details;
        },
        showActive(row) {
            return row.is_active == true;
        }
    },
    computed: {
        tableFields() {
            var newFields = [];
            if (this.semester) {
                newFields.push({
                    key: "registration_open",
                    label: "Allow Registration",
                    sortable: true
                });
            }
            return [...this.fields, ...newFields, { key: "actions" }]
        },
        members() {
            if (!this.items) return [];
            return this.items.filter((item) => {
                return (this.show.includes("active") && item.is_active) || (this.show.includes("inactive") && !item.is_active);
            });
        },
        ...mapState(["semester"])
    },
    components: { UserRowDetails, UserActiveToggle, UserAllowRegistrationToggle, ChangeUserRegistrationAllowedAlert }
}
</script>
<style scoped>
    .scroll-x {
        max-width:  100%;
        overflow-x: scroll;
    }
</style>