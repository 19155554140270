<template>
    <div class="text-center">
        <b-checkbox
            :key="id"
            :checked="value"
            @change="setActive"
        ></b-checkbox>
    </div>
</template>
<script>
export default {
    props: ["id", "value"],
    methods: {
        setActive(is_active) {
            this.$api.post("/api/admin/users/set-active", {
                user_id:    this.id,
                is_active:  is_active
            }).then((response) => {
                var data = response.data;
                this.$bvToast.toast(data.message, {
                    title:   (data.success) ? "Success" : "Error",
                    variant: (data.success) ? "success" : "danger",
                    autoHideDelay: 5000,
                    appendToast: true
                });
                this.$emit("input", is_active);
            })
        }
    }
}
</script>