<template>
    <div>
        <membership-form-info
            @close="$emit('toggle')"
            :user="user"
        ></membership-form-info>
        <login-info
            :id="user.id"
            :max="3"
        ></login-info>
        <hr/>
        <div class="text-center">
            <button
                class="sc-btn pill mr-2"
                @click="confirmRemoveMember(user)"
            >
                <font-awesome-icon icon="user-minus" class="mr-1"></font-awesome-icon>
                Remove Member
            </button>
            <a
                :href="row.invoice_url"
                class="sc-btn pill mr-2"
                target="_blank"
            >
                <font-awesome-icon icon="file-pdf" class="mr-1"></font-awesome-icon>
                View Statement
            </a>
        </div>
    </div>
</template>
<script>
import MembershipFormInfo from './info/MembershipFormInfo.vue';
import LoginInfo          from './info/LoginInfo.vue';
export default {
    props: ["row"],
    components: { MembershipFormInfo, LoginInfo },
    methods: {
        confirmRemoveMember(user) {
            this.$bvModal.msgBoxConfirm(
                "Please confirm that you want to remove this member's access to your group's resources (Email: \"" +
                    user.email + "\").",
                {
                    title           : "Please confirm to remove user access",
                    size            : "sm",
                    buttonSize      : "sm",
                    okVariant       : "danger",
                    okTitle         : "YES",
                    cancelTitle     : "NO",
                    footerClass     : "p-2",
                    hideHeaderClose : false,
                    centered        : true
                }
            ).then(confirmed => {
                if (confirmed) {
                    this.removeGroupMember(user);
                }
            })
            .catch(err => {
                this.$bvToast.toast(err, {
                    title         : "Remove failed!",
                    variant       : "danger",
                    autoHideDelay : 5000,
                    appendToast   : true
                });
            });
        },
        removeGroupMember({ id }) {
            this.$api.post("/api/group/admin/remove-member", {
                user_id: id 
            }).then(({ data }) => {
                this.$bvToast.toast(data.message, {
                    title:   (data.success) ? "Success" : "Error",
                    variant: (data.success) ? "success" : "danger",
                    autoHideDelay: 5000,
                    appendToast: true
                });
                this.$emit('refresh');
            });
        }
    },
    computed: {
        user() {
            return this.row.user;
        }
    }
}
</script>