<template>
    <div class="ml-4 mb-4">
        <b-row>
            <b-col sm="12">
                <button
                    @click="close()"
                    class="close"
                >
                    &times;
                </button>
            </b-col>
        </b-row>
        <div
            v-if="loading"
            class="text-center"
        >
            <b-spinner variant="primary" />
        </div>
        <b-row
            v-if="user.person"
        >
            <b-col sm="2">
                <b>Name</b>
            </b-col>
            <b-col sm="8">
                {{ user.person.first_name }} {{ user.person.last_name}}
            </b-col>
        </b-row>
        <b-row
            v-if="user.person2"
            class="mt-1"
        >
            <b-col sm="2">
                <b>Spouse's Name</b>
            </b-col>
            <b-col sm="8">
                {{ user.person2.first_name }} {{ user.person2.last_name}}
            </b-col>
        </b-row>
        <b-row
            v-if="user.person.birthdate"
            class="mt-1"
        >
            <b-col sm="2">
                <b>Birthdate</b>
            </b-col>
            <b-col sm="8">
                {{ user.person.birthdate }}
            </b-col>
        </b-row>
        <b-row
            v-if="membershipData.cell_phone"
            class="mt-1"
        >
            <b-col sm="2">
                <b>Cell Phone</b>
            </b-col>
            <b-col sm="8">
                {{ membershipData.cell_phone }}
            </b-col>
        </b-row>
        <b-row
            v-if="membershipData.spouse_cell_phone"
            class="mt-1"
        >
            <b-col sm="2">
                <b>Spouse's Cell Phone</b>
            </b-col>
            <b-col sm="8">
                {{ membershipData.spouse_cell_phone }}
            </b-col>
        </b-row>
        <b-row
            v-if="membershipData.home_phone"
            class="mt-1"
        >
            <b-col sm="2">
                <b>Home Phone</b>
            </b-col>
            <b-col sm="8">
                {{ membershipData.home_phone }}
            </b-col>
        </b-row>
        <b-row
            v-if="membershipData.addr1_addr"
            class="mt-1"
        >
            <b-col sm="2">
                <b>Address</b>
            </b-col>
            <b-col sm="8">
                <div>{{ membershipData.addr1_addr }}</div>
                <div>{{ membershipData.addr1_city }}, {{ membershipData.addr1_state }} {{ membershipData.addr1_zip }}</div>
            </b-col>
        </b-row>
        <b-row
            v-if="membershipData.church_of_fellowship"
            class="mt-1"
        >
            <b-col sm="2">
                <b>Church of Fellowship</b>
            </b-col>
            <b-col sm="8">
                {{ membershipData.church_of_fellowship }}
            </b-col>
        </b-row>
        <div v-if="pdf_url" class="text-center mt-2">
            <a
                class="sc-btn pill"
                :href="pdf_url"
                target="_blank"
            >
                <font-awesome-icon icon="file-pdf" class="mr-2"></font-awesome-icon>
                View Membership
            </a>
        </div>
    </div>
</template>
<script>
import { getToken } from "@/api/client"
import { mapState } from 'vuex';
export default {
    props: ["user"],
    data() {
        return {
            membershipData: {},
            pdf_url: "",
            loading: false
        };
    },
    mounted() {
        this.loadMembershipForm();
    },
    methods: {
        async loadMembershipForm() {
            this.loading = true;
            const { data } = await this.$api.get("/api/admin/users/membership-form", { params: { user_id: this.user.id } });
            this.membershipData = data.membershipData;
            this.pdf_url = this.buildPdfUrl(data.pdf_url);
            this.loading = false;
        },
        buildPdfUrl(url) {
            if (this.semester && this.group && this.user) {
                const pdf_url = new URL(url);
                pdf_url.searchParams.set("token", getToken());
                pdf_url.searchParams.set("group_id", this.group.group_id);
                pdf_url.searchParams.set("semester_id", this.semester.id);
                pdf_url.searchParams.set("user_id", this.user.id);
                return pdf_url.toString();
            }
            return "";
        },
        close() {
            this.$emit("close");
        }
    },
    computed: mapState(["semester", "group"])
}
</script>