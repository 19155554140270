<template>
    <b-container>
            <b-card class="text-center">
                <h2>
                    <font-awesome-icon
                        icon="users"
                        class="mr-2"
                    ></font-awesome-icon>
                    Members
                </h2>
                <div class="d-flex justify-content-center flex-wrap mt-3 gap-3">
                    <a
                        v-if="hasMembers"
                        class="sc-btn pill mr-2"
                        :href="mailtoAll"
                    >
                        <font-awesome-icon
                            class="mr-1"
                            icon="envelope"
                        ></font-awesome-icon>
                        Email All
                    </a>
                    <router-link
                        :to="{ name: 'user_waiting' }"
                        class="sc-btn pill mr-2"
                    >
                        <font-awesome-icon
                            class="mr-1"
                            icon="user-clock"
                        ></font-awesome-icon>
                        Join Requests
                    </router-link>
                    <router-link
                        :to="{ name: 'invite_group_members', params: { group_id: group.group_id } }"
                        class="sc-btn pill"
                    >
                        <font-awesome-icon
                            icon="plus-circle"
                            class="mr-1"
                        ></font-awesome-icon>
                        Invite User
                    </router-link>
                    <a
                        href="#"
                        class="sc-btn pill ml-2"
                        @click="exportTable()"
                    >
                        <font-awesome-icon
                            icon="file-download"
                            class="mr-1"
                        ></font-awesome-icon>
                        Export
                    </a>
                </div>
            </b-card>
        
        <b-skeleton-wrapper :loading="loading">
            <template #loading>
                <b-card class="mt-2">
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                </b-card>
            </template>
            <UserTable
                :loading="loading"
                :items="items"
                @refresh="loadUsers()"
            />
        </b-skeleton-wrapper>
    </b-container>
</template>
<script>
import UserTable from './table/UserTable.vue';
import { mapState } from 'vuex';
import { downloadFile } from '@/api/downloadFile';
import { getToken } from "@/api/client";

export default {
    data() {
        return {
            loading: false,
            items: []
        };
    },
    components: { UserTable },
    mounted() {
        if (this.group) {
            this.loadUsers();
        }
    },
    methods: {
        loadUsers() {
            this.loading = true;
            this.$api.get(
                "/api/admin/users/list"
            ).then(({ data }) => {
                let settings = {};
                data.settings.forEach(({user_id, registration_open}) => {
                    settings[user_id] = registration_open;
                });
                this.items = data.users.map((member) => {
                
                    if (this.semester) {
                        // Update url with authentication token if in a semester
                        var invoice_url = new URL(member.invoice_url);
                        invoice_url.searchParams.set("token", getToken());
                        invoice_url.searchParams.set("semester_id", this.semester.id);
                        member.invoice_url = invoice_url.toString();

                        // Update registration open status
                        member.registration_open = settings[member.user.id] || !!this.semester.registration_open;
                    }
                    return member;
                });
                this.loading = false;
            });
        },
        exportTable() {
            downloadFile(
                `/api/admin/export/users/download.xlsx`,
                `${(this.currentSemesterID) ? this.semester.label : 'Active'} Users.xlsx`
            );
        }
    },
    computed: {
        hasMembers() {
            return this.items.length > 0;
        },
        mailtoAll() {
            return "mailto:" + this.items.filter((user) => user.is_active == 1).map((user) => user.email).join(",");
        },
        ...mapState(["currentSemesterID", "group", "semester"])
    },
    watch: {
        group () {
            this.loadUsers();
        }
    }
  }
</script>