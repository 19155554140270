<template>
    <div class="text-center">
        <b-checkbox
            :key="id"
            :checked="value"
            @change="setActive"
            switch
            :disabled="!!semester.registration_open"
        ></b-checkbox>
    </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
    props: ["id", "value"],
    methods: {
        setActive(registration_open) {
            this.$api.post("/api/admin/set-registration-open", {
                user_id:    this.id,
                registration_open
            }).then((response) => {
                var data = response.data;
                this.$bvToast.toast(data.message, {
                    title:   (data.success) ? "Success" : "Error",
                    variant: (data.success) ? "success" : "danger",
                    autoHideDelay: 5000,
                    appendToast: true
                });
                this.$emit("input", registration_open);
            })
        }
    },
    computed: mapState(["semester"])
}
</script>