<template>
    <b-alert
        variant="info"
        class="mt-2"
        :show="semester && !!semester.registration_open"
    >
        <font-awesome-icon icon="info-circle" class="mr-2" />
        Registration is open for the current (active) semester. At this time, you may not enable or disable registration per member. To change who can register, please close registration from <router-link :to="{ name: 'semester_settings', params: { id: semester.id } }">semester settings.</router-link>
    </b-alert>
</template>
<script>
import { mapState } from 'vuex'
export default {
    computed: mapState(["semester"])
}
</script>